import { Card, Col } from "antd";
import { ReactNode } from "react";

interface ICCard {
  colSpan?: number;
  colXs?: number;
  colSm?: number;
  colMd?: number;
  colLg?: number;
  title?: string;
  children?: ReactNode;
}
const CCard: React.FC<ICCard> = ({
  children,
  colLg,
  colMd,
  colSm,
  colSpan,
  colXs,
  title,
}) => {
  return (
    <>
      <Col span={colSpan} xs={colXs} sm={colSm} md={colMd} lg={colMd}>
        <Card
          title={title}
          size="small"
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            borderRadius: 10,
          }}
          bodyStyle={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {children}
        </Card>
      </Col>
    </>
  );
};
export default CCard;
