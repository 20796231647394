import { Col, Row, Table, message, TableColumnsType, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import { TitlePage } from "../../../../components/TitlePage/TitlePage";
import api from "../../../../services/api";
import moment from "moment-timezone";
import { TfiReceipt } from "react-icons/tfi";
import "./style.css";
import { IColumnTable } from "../../interfaces";
import { ColumnsTableWagon } from "../../constants";
import BoletimMRS from "../BoletimMRS/BoletimMRS";
import CModal from "../../../../components/Modal/Modal";

const HistoricoComponent = () => {
  const [loadingTable, setLoadingTable] = useState(true);
  const [data, setData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [boletimType, setBoletimType] = useState<any>();
  const [boletim, setBoletim] = useState<any>();

  const getData = useCallback(() => {
    api
      .get("/balancaferroviaria/getAll")
      .then(({ data }) => {
        const dataDESC = data.sort((a: any, b: any) =>
          new Date(a.data!) < new Date(b.data!) ? 1 : -1
        );

        setData(dataDESC);
        setLoadingTable(false);
      })
      .catch((error) => message.error("Não foi possivel buscar as pesagens"));
  }, []);

  const getBoletim = useCallback(
    ({ prefixo, tipoBoletim }: { prefixo: string; tipoBoletim: string }) => {
      api
        .get(
          `/boletimACM/getBoletimACM?${
            tipoBoletim === `boletimCarregado`
              ? `prefixoCarregado=${prefixo}`
              : `prefixoVazio=${prefixo}`
          }`
        )
        .then(({ data }) => setBoletim(data?.[tipoBoletim!] || {}));
    },
    []
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const ColumnsTableHistoric: TableColumnsType<IColumnTable> = [
    {
      align: "center",
      title: "Data",
      dataIndex: "data",
      key: "data",
      render(value: string) {
        return moment(value).format("DD/MM/YYYY hh:mm");
      },
    },
    {
      align: "center",
      title: "Prefixo Vazio",
      key: "boletimVazio",
      render: (value: any) =>
        Object.keys(value?.boletimACM).length > 0
          ? value?.boletimACM?.prefixoVazio
          : value?.nomeComposicao,
    },
    {
      align: "center",
      title: "Boletim MRS Vazio",
      key: "boletimVazio",
      render: (value: any) => (
        <TfiReceipt
          size={24}
          onClick={() => {
            getBoletim({
              prefixo:
                Object.keys(value?.boletimACM).length > 0
                  ? value?.boletimACM?.prefixoVazio
                  : value?.nomeComposicao,
              tipoBoletim: "boletimVazio",
            });
            setShowModal(true);
            setBoletimType("Vazio");
          }}
        />
      ),
    },
    {
      align: "center",
      title: "Prefixo Carregado",
      key: "boletimCarregado",
      render: (value: any) =>
        Object.keys(value?.boletimACM).length > 0
          ? value?.boletimACM?.prefixoCarregado
          : value?.nomeComposicao,
    },
    {
      align: "center",
      title: "Boletim MRS Carregado",
      key: "boletimCarregado",
      render: (value: any) => (
        <TfiReceipt
          size={24}
          onClick={() => {
            getBoletim({
              prefixo:
                Object.keys(value?.boletimACM).length > 0
                  ? value?.boletimACM?.prefixoCarregado
                  : value?.nomeComposicao,
              tipoBoletim: "boletimCarregado",
            });
            setBoletimType("Carregado");
            setShowModal(true);
          }}
        />
      ),
    },
    {
      align: "center",
      title: "Situação Pesagem",
      dataIndex: "vagoes",
      key: "vagoes",
      render: (value: any, listData: any) => {
        const composicaoAlarmada =
          value.filter(
            (e: any) =>
              e.status === "max_exceeded" || e?.vagoesComOcorrencia?.length
          ).length > 0;
        return (
          <Tag color={composicaoAlarmada ? "red" : "green"}>
            {composicaoAlarmada ? "ALARMADA" : "OK"}
          </Tag>
        );
      },
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TitlePage title="Histórico de Pesagem" />
      </Col>
      <Col span={24}>
        <Table
          columns={ColumnsTableHistoric}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 15,
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Table
                  columns={ColumnsTableWagon}
                  dataSource={record.vagoes}
                  key={"_id"}
                  size={"small"}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 150,
                  }}
                />
              );
            },
          }}
          dataSource={data}
          size="small"
          loading={loadingTable}
          scroll={{ x: "100%" }}
          rowKey={"_id"}
        />
      </Col>

      <CModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
        title={`Detalhes do Boletim`}
        children={<BoletimMRS boletimType={boletimType} content={boletim} />}
        width={"95%"}
      />
    </Row>
  );
};

export default HistoricoComponent;
