import { Button, Flex } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { CiEdit, CiTrash } from "react-icons/ci";
import { IEquipamento } from "../../../../interfaces/IEquipamento";
import { IProduto } from "../../../../interfaces/IProduto";
import { IVagao } from "../../../BalancaFerroviaria/interfaces/IPesagemFerroviariaV2";
import { IOperacaoCarregamento } from "../../../../interfaces/IOperacaoCarregamento";

interface ITableCarregamento {
  data?: IOperacaoCarregamento[];
  onEdit?: (params?: any) => void;
  onDelete?: (params?: any) => void;
  onShow?: (params?: any) => void;
}

export const ColumnsTableOperacaoCarregamento = ({
  data,
  onDelete,
  onEdit,
  onShow,
}: ITableCarregamento): any => {
  return [
    {
      // key: "data",
      dataIndex: "data",
      title: String("Data").toUpperCase(),
      render: (value: any, record: any) =>
        moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      // key: "produtoId",
      dataIndex: "produtoId",
      title: String("Produto").toUpperCase(),
      render: (value: any, record: any) => value?.descricao,
    },
    {
      // key: "equipamentoId",
      dataIndex: "equipamentoId",
      title: String("Máquina").toUpperCase(),
      render: (value: any, record: any) => value?.descricao,
    },
    {
      // key: "vagaoId",
      dataIndex: "vagaoId",
      title: String("Vagão").toUpperCase(),
      render: (value: any, record: any) => value?.codigoVagao,
    },
    {
      // key: "peso",
      dataIndex: "peso",
      title: String("Peso").toUpperCase(),
      render: (value: any, record: any) => value,
    },
    {
      // key: "numeroPesagem",
      dataIndex: "numeroPesagem",
      title: String("numeroPesagem").toUpperCase(),
      render: (value: any, record: any) => value,
    },
    // {
    //   key: "Produto",
    //   dataIndex: "produtoId",
    //   title: String("Produto").toUpperCase(),
    //   render: (value: any, record: any) => value?.descricao,
    // },
    // {
    //   title: "#",
    //   dataIndex: "",
    //   key: "x",
    //   fixed: "right",
    //   render: (text: any, record: any, index: any) => (
    //     <Flex wrap="wrap" gap="small">
    //       <Button
    //         icon={
    //           <CiEdit
    //             // color=""
    //             size={16}
    //           />
    //         }
    //         onClick={() => onEdit!(record)}
    //       />
    //       <Button
    //         icon={<CiTrash color="red" size={16} />}
    //         onClick={() => onDelete!(record)}
    //       />
    //     </Flex>
    //   ),
    // },
  ];
};
