import { Col, Row, Table, message, TableColumnsType } from "antd";
import { useCallback, useEffect, useState } from "react";
import { TitlePage } from "../../../../components/TitlePage/TitlePage";
import api from "../../../../services/api";
import moment from "moment-timezone";
import { TfiReceipt } from "react-icons/tfi";
import "./style.css";
import { IColumnTable } from "../../interfaces";
import BoletimMRS from "../BoletimMRS/BoletimMRS";
import CModal from "../../../../components/Modal/Modal";

const BoletimACMComponent = () => {
  const [loadingTable, setLoadingTable] = useState(true);
  const [data, setData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [boletimType, setBoletimType] = useState<any>();
  const [boletim, setBoletim] = useState<any>();

  const getData = useCallback(() => {
    api
      .get("/boletimACM/getAll")
      .then(({ data }) => {
        const dataDESC = data.sort((a: any, b: any) =>
          new Date(a.data!) < new Date(b.data!) ? 1 : -1
        );

        setData(dataDESC);
        setLoadingTable(false);
      })
      .catch((error) => message.error("Não foi possivel buscar as pesagens"));
  }, []);

  const getBoletim = useCallback(
    ({ prefixo, tipoBoletim }: { prefixo: string; tipoBoletim: string }) => {
      api
        .get(
          `/boletimACM/getBoletimACM?${
            tipoBoletim === `boletimCarregado`
              ? `prefixoCarregado=${prefixo}`
              : `prefixoVazio=${prefixo}`
          }`
        )
        .then(({ data }) => setBoletim(data?.[tipoBoletim!] || {}));
    },
    []
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const ColumnsTableBoletim: TableColumnsType<IColumnTable> = [
    {
      align: "center",
      title: "Data",
      dataIndex: "data",
      key: "data",
      render(value: string) {
        
        if (moment(value,['DD/MM/YYYY HH:mm:ss','YYYY-MM-DD HH:mm']).isValid()) {
          return value;
        }  
      },
    },
    {
      align: "center",
      title: "Prefixo Vazio",
      key: "boletimVazio",
      render: (value: any) => value?.prefixoVazio,
    },
    {
      align: "center",
      title: "Boletim MRS Vazio",
      key: "boletimVazio",
      render: (value: any) => (
        <TfiReceipt
          size={24}
          onClick={() => {
            getBoletim({
              prefixo: value?.prefixoVazio,
              tipoBoletim: "boletimVazio",
            });
            setShowModal(true);
            setBoletimType("Vazio");
          }}
        />
      ),
    },
    {
      align: "center",
      title: "Prefixo Carregado",
      key: "boletimCarregado",
      render: (value: any) => value?.prefixoCarregado,
    },
    {
      align: "center",
      title: "Boletim MRS Carregado",
      key: "boletimCarregado",
      render: (value: any) => (
        <TfiReceipt
          size={24}
          onClick={() => {
            getBoletim({
              prefixo: value?.prefixoCarregado,
              tipoBoletim: "boletimCarregado",
            });
            setShowModal(true);
            setBoletimType("Carregado");
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TitlePage title="Boletim ACM MRS" />
        </Col>
        <Col span={24}>
          <Table
            columns={ColumnsTableBoletim}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 15,
            }}
            dataSource={data}
            size="small"
            loading={loadingTable}
            scroll={{ x: "100%" }}
            rowKey={"_id"}
          />
        </Col>

        <CModal
          open={showModal}
          onCancel={() => setShowModal(false)}
          onOk={() => setShowModal(false)}
          title={`Detalhes do Boletim`}
          children={<BoletimMRS boletimType={boletimType} content={boletim} />}
          width={"95%"}
        />
      </Row>
    </>
  );
};

export default BoletimACMComponent;
