import { Col, Image, Row } from "antd";
import { ReactNode } from "react";
import { Logo, Text, Container } from "./style";
import { GiRailRoad } from "react-icons/gi";
import { useAuth } from "../../context/AuthContext";

import TcsmgLogo from "../../assets/tcsmg/logo-tcs-01.png";
import LogoComponent from "../Logo";

interface IHeader {
  children?: ReactNode;
  title?: string;
  showIcon?: boolean;
}
const Header: React.FC<IHeader> = ({ children, title, showIcon }) => {
  const { authenticated } = useAuth();

  return (
    <Container>
      <Row>
        <Col className="gutter-row" sm={24}>
          {!authenticated && (
            <Logo>
              <Image src={TcsmgLogo} height={"70px"} preview={false} />
            </Logo>
          )}

          {showIcon && (
            <Col className="gutter-row" sm={1}>
              <GiRailRoad size={26} color="white" />
            </Col>
          )}
          {title && (
            <>
              <Text style={{ fontSize: 24 }}>{title}</Text>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default Header;
