import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import {
  Button,
  Row,
  Flex,
  Form,
  DatePicker,
  Input,
  Transfer,
  Select,
  FormInstance,
  UploadProps,
  Upload,
  message,
  Slider,
  Spin,
} from "antd";
import { CiTrash, CiSearch, CiFloppyDisk, CiEdit, CiPen } from "react-icons/ci";
import { Action } from "../../pages/Carregamento/style";
import api from "../../../../services/api";
import { NavLink } from "react-router-dom";
import { TransferDirection, TransferListProps } from "antd/es/transfer";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";

interface ITableBDT {
  eventoCarregamento?: any[];
  setEventoCarregamento?: any;
  onEdit?: (params?: any) => void;
  onDelete?: (params?: any) => void;
  onShow?: (params?: any) => void;
  showBtnEdit?: boolean;
  showBtnDelete?: boolean;
}

interface IForm {
  data?: any;
  onFinish?: (data: any) => void;
  form?: FormInstance<any>;
}

export const ColumnsTableBDT = ({
  eventoCarregamento,
  setEventoCarregamento,
  onEdit,
  onShow,
  onDelete,
  showBtnEdit,
  showBtnDelete,
}: ITableBDT): any => {
  return [
    {
      key: "solicitante",
      dataIndex: "solicitante",
      title: String("solicitante").toUpperCase(),
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      key: "evento",
      dataIndex: "evento",
      title: String("evento").toUpperCase(),
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      key: "dataInicio",
      dataIndex: "dataInicio",
      title: String("Início").toUpperCase(),
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      key: "dataFim",
      dataIndex: "dataFim",
      title: String("Fim").toUpperCase(),
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value || "",
    },
    {
      key: "tempo",
      dataIndex: "tempo",
      title: String("Tempo").toUpperCase(),
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => (value ? value : ""),
    },
    // {
    //   key: "observacao",
    //   dataIndex: "observacao",
    //   title: String("observacao").toUpperCase(),
    //   render: (value: any, record: any) => value,
    // },
    {
      key: "usuario",
      dataIndex: "usuario",
      title: String("usuário").toUpperCase(),
      responsive: ["xs", "sm", "md"],
      render: (value: any, record: any) => value?.fullName || "",
    },
    showBtnEdit || showBtnDelete
      ? {
          key: "action",
          title: "#",
          fixed: "right",
          dataIndex: "_id",
          responsive: ["xs", "sm", "md"],
          render: (text: any, record: any, index: any) => (
            <Flex gap={"small"}>
              {showBtnEdit && (
                <Button
                  key={`edit-${record?._id}`}
                  size="small"
                  type="default"
                  icon={
                    <CiEdit
                      // color="red"
                      size={16}
                      onClick={() => onEdit!(record)}
                    />
                  }
                />
              )}
              {showBtnDelete && (
                <Button
                  key={`delete-${record?._id}`}
                  size="small"
                  type="default"
                  icon={
                    <CiTrash
                      color="red"
                      size={16}
                      onClick={() => onDelete!(record?._id)}
                    />
                  }
                />
              )}
            </Flex>
          ),
        }
      : {},
  ];
};

export const BDTFields = () => {
  const [evento, setEvento] = useState<any[]>([]);
  const [requerente, setRequerente] = useState<any[]>([]);
  const [eventoSecundario, setEventoSecundario] = useState<any[]>([]);
  const [isEventoPDC, setIsEventoPDC] = useState(false);

  const onChangeEvento = (value: any, data: any) =>
    String(data?.labe).split(" - ")?.[0] === "PDC"
      ? setIsEventoPDC(true)
      : setIsEventoPDC(false);

  useEffect(() => {
    api.get(`/evento`).then(({ data }) => {
      const listEvento = (data || [])
        .filter((e: any) => !e.eventoSecundario)
        .map((e: any) => {
          return {
            value: e?._id,
            label: `${e?.codigoEvento} - ${e?.descricao}`,
          };
        });
      setEvento(listEvento);

      const listEventoSecundario = (data || [])
        .filter((e: any) => e.eventoSecundario === true)
        .map((e: any) => {
          return {
            value: e?._id,
            label: `${e?.codigoEvento} - ${e?.descricao}`,
          };
        });
      setEventoSecundario(listEventoSecundario);
    });
  }, []);

  useEffect(() => {
    setRequerente([
      { value: "TCS", label: "TCS" },
      { value: "MRS", label: "MRS" },
    ]);
  }, []);

  return [
    {
      label: "Id",
      name: "_id",
      hidden: true,
      component: {
        type: Input,
        props: {
          hidden: true,
        },
      },
    },
    {
      label: "Data/Hora Evento",
      name: "data",
      key: "data",
      component: {
        type: DatePicker,
        props: {
          showTime: { format: "HH:mm" },
          format: "DD/MM/YYYY HH:mm",
        },
      },
    },
    {
      label: "Evento",
      name: "eventoId",
      component: {
        type: Select,
        props: {
          style: { width: "100%" },
          placeholder: "Selecione",
          options: evento,
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input: any, option: any) =>
            String(option.label).split(" - ")?.[0].toUpperCase() ===
            String(input).toUpperCase(),
          onChange: onChangeEvento,
        },
      },
    },
    {
      label: "Evento Secundário",
      name: "eventoSecundarioId",
      ...(!isEventoPDC ? { hidden: true } : {}),
      component: {
        type: Select,
        props: {
          style: { width: "100%" },
          placeholder: "Selecione",
          options: eventoSecundario,
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input: any, option: any) =>
            String(option.label).split(" - ")?.[0].toUpperCase() ===
            String(input).toUpperCase(),
        },
      },
    },

    {
      label: "Solicitante",
      name: "requerente",
      component: {
        type: Select,
        props: {
          style: { width: "100%" },
          placeholder: "Selecione",
          options: requerente,
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input: any, option: any) =>
            String(option.label).toUpperCase() === String(input).toUpperCase(),
        },
      },
    },
    {
      label: "Observação",
      name: "observacao",
      component: {
        type: TextArea,
        props: {
          style: { width: "100%" },
        },
      },
    },
  ];
};

export const NewBDTForm = ({ onFinish }: IForm) => {
  const [form] = useForm();

  type LayoutType = Parameters<typeof Form>[0]["layout"];
  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 4 }, wrapperCol: { span: 20 } }
      : null;

  return (
    <Form
      {...formItemLayout}
      layout={"horizontal"}
      form={form}
      onFinish={onFinish}
    >
      {BDTFields()
        .filter((e) => e?.name !== "_id")
        .map((e, index) => (
          <Form.Item
            key={index}
            {...e}
            children={React.createElement(
              e?.component.type as any,
              e?.component.props as any
            )}
          />
        ))}

      <Form.Item>
        <Row justify={"end"}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<CiFloppyDisk size={16} />}
          >
            Salvar
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export const EditBDTForm = ({ onFinish, data }: IForm) => {
  const [form] = useForm();

  type LayoutType = Parameters<typeof Form>[0]["layout"];
  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 4 }, wrapperCol: { span: 20 } }
      : null;

  useEffect(() => {
    form?.setFieldsValue({
      ...data,
      data: dayjs(
        dayjs(data?.data, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm")
      ),
    });
  }, [data]);

  return (
    <Form
      {...formItemLayout}
      layout={"horizontal"}
      form={form}
      onFinish={onFinish}
    >
      {BDTFields().map((e, index) => (
        <Form.Item
          key={index}
          {...e}
          children={React.createElement(
            e.component.type as any,
            e.component.props as any
          )}
        />
      ))}

      <Form.Item>
        <Row justify={"end"}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<CiFloppyDisk size={16} />}
          >
            Salvar
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
