import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
        --codar-font-color-light: #ffffff;
        --codar-font-family: 'Clear Sans Medium', arial;
    }

    * {
        font-family: 'Montserrat', sans-serif;
        margin: 0;
        padding: 0;
        outline: 0;
    }
`;
