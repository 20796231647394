import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Col,
  Form,
  List,
  message,
  Row,
  Table,
  Typography,
  Upload,
} from "antd";
import api from "../../../../services/api";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./style.css";
import CPanel from "./components/CPanel";
import CCard from "./components/CCard";

import {
  IPesagemFerroviariaV2,
  IVagao,
} from "../../interfaces/IPesagemFerroviariaV2";
import { TitlePage } from "../../../../components/TitlePage/TitlePage";
import { IoCloudUploadOutline } from "react-icons/io5";

const BalancaFerroviariaComponent = () => {
  const [loading] = useState(false);
  const [pesagemFerroviaria, setPesagemFerroviaria] =
    useState<IPesagemFerroviariaV2>();
  const [ultimaPesagem, setUltimaPesagem] = useState<IVagao>();
  const [countOccurrence, setCountOccurrence] = useState(0);

  // recuperar os dados das pesagens
  const initialData = useCallback(() => {
    api
      .get<IPesagemFerroviariaV2>("/balancaferroviaria")
      .then(({ data }) => {
        const { vagoes } = data;

        // ordenar composicao DESC ( -1 )
        const dataOrder = vagoes?.sort((a, b) =>
          a.ordemVagao < b.ordemVagao ? 1 : -1
        );
        setPesagemFerroviaria({
          ...data,
          vagoes: dataOrder,
        });
        setUltimaPesagem(dataOrder?.[0] || {});

        const countOccurrence = dataOrder
          // ?.slice(1, data.length)  // So sera levado em consideracao pesagens a partir da posicao 01, pois o vagao pesado no momento nao deverá ser contabilizado na primeira tentativa
          ?.slice(0, dataOrder.length)
          ?.filter((o) => o?.vagoesComOcorrencia.length > 0 || o.alerta);
        setCountOccurrence(countOccurrence?.length!);

        // setHeaderPesagem(dataOrder?.[0]?.headerPesagem);
        // setCountWagon(
        // dataOrder?.[0]?.headerPesagem.find((o) => o.tipoVeiculo === "GDT")
        // ?.qtdVeiculo || 0
        // );
        // setCountLocomotiva(
        // dataOrder?.[0]?.headerPesagem.find((o) => o.tipoVeiculo === "LOC6L")
        // ?.qtdVeiculo || 0
        // );
      })
      .catch(() => {
        message.error("Não foi possivel buscar as pesagens");
      });
  }, []);

  // alterar tipo do vagao
  // const onChangeTypeWagon = (e: RadioChangeEvent) => {
  //   setLoadingSpin(true);
  //   api
  //     .post("/balancaferroviaria/changeWagonType", {
  //       wagonType: e.target.value,
  //       code: pesagemFerroviaria?.nomeComposicao,
  //     })
  //     .then((data) => setLoadingSpin(false))
  //     .catch((e) => {
  //       message.error(
  //         "Erro ao selecionar a categoria da composição. Tente novamente"
  //       );
  //       setLoadingSpin(false);
  //     });
  // };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!loading) {
      interval = setInterval(() => {
        initialData();
      }, 1200);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
  }, [initialData, loading]);

  const onFinish = (value: any) => console.log(value);


  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TitlePage title="Validação de Pesagem" />
        </Col>

        <Col span={24}>
          <div
            style={{
              float: "right",
            }}
          >
            <Form onFinish={onFinish} style={{ width: "100%" }}>
              <Form.Item>
                <Upload
                  name="file"
                  accept={".xls,.xlsx"}
                  action={`${process.env.REACT_APP_MAXIMUS_APP_BACKEND}/boletimACM/uploadPlanilhaAcm`}
                  onChange={({file,fileList,event}) => {
                    if(file.status === 'done'){
                      message.success("Upload realizado com sucesso")
                    }
                  }}
                  maxCount={1}
                  method="POST"
                  headers={{
                  "Authorization":`Bearer ${window.localStorage.getItem("@App:token")}`
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ textAlign: "center" }}>
                      <IoCloudUploadOutline
                        size={32}
                        color="orange"
                        fontWeight={"300"}
                      />
                    </span>
                    <span style={{ textAlign: "center" }}>
                      Upload Planilha ACM{" "}
                    </span>
                  </div>
                </Upload>
              </Form.Item>
            </Form>
          </div>
        </Col>

        <CPanel
          idKey={1}
          title="Composição"
          colSpan={24}
          useBackGroundColorHeader={true}
          open={true}
        >
          <Row gutter={[2, 2]}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 2,
              }}
            >
              {pesagemFerroviaria?.nomeComposicao || ""}
            </Typography.Title>
          </Row>
        </CPanel>

        <CPanel
          idKey={3}
          title="Vagão Atual"
          colSpan={24}
          open={true}
          useBackGroundColorHeader={true}
        >
          <Row gutter={[2, 2]}>
            <CCard title="Posição" colSpan={12}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 2,
                }}
              >
                {ultimaPesagem?.ordemVagao || 0}
              </Typography.Title>
            </CCard>
            <CCard title="Peso" colSpan={12}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 2,
                }}
              >
                {`${Intl.NumberFormat("pt-BR").format(
                  ultimaPesagem?.pesoFinal || 0
                )} TNS`}
              </Typography.Title>
            </CCard>
            <CCard title="Vagão" colSpan={12}>
              <Typography.Title level={5}>
                {ultimaPesagem?.tipo || ""}
              </Typography.Title>
            </CCard>
            <CCard title="Peso Max. Permitido" colSpan={12}>
              <Typography.Title level={5}>
                {`${Number(ultimaPesagem?.pesoMaximoPermitido || 0).toFixed(
                  0
                )} TNS`}
              </Typography.Title>
            </CCard>
          </Row>
        </CPanel>

        {/* status */}
        <CPanel idKey={4} title="Status da Pesagem" colSpan={24} open={true}>
          <Alert
            style={{
              margin: 0,
              border: 0,
              display: "block",
              textAlign: "center",
            }}
            description={
              <span>
                {countOccurrence} ocorrência{countOccurrence < 1 ? "" : "s"}
              </span>
            }
            type={countOccurrence > 0 ? "error" : "success"}
            showIcon
          />
        </CPanel>

        {/* vagoes pesados  */}
        <CPanel
          idKey={5}
          title="Lista de Vagões"
          colSpan={24}
          useBackGroundColorHeader={true}
        >
          <List
            itemLayout="vertical"
            size="small"
            dataSource={pesagemFerroviaria?.vagoes || []}
            renderItem={(item: IVagao) => (
              <Badge.Ribbon
                text={
                  item.alerta ? (
                    <ExclamationCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                // text={item.alerta ? "NÃO CONFORME" : "CONFORME"}
                color={item.alerta ? "red" : "green"}
              >
                <List.Item
                  style={{
                    backgroundColor: item.alerta ? "#fff1f0" : "#fff",
                  }}
                >
                  <List.Item.Meta
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title={
                      <Typography.Paragraph>
                        <Typography.Text>Vagão</Typography.Text>{" "}
                        <Typography.Text>
                          {item?.ordemVagao || ""}
                        </Typography.Text>
                      </Typography.Paragraph>
                    }
                    description={
                      <Row gutter={[4, 4]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          {/* Velocidade */}
                          <Typography.Paragraph
                            style={{ marginBottom: "1px", fontSize: "13px" }}
                          >
                            <Typography.Text>-Velocidade: </Typography.Text>
                            <Typography.Text strong>
                              {`${
                                Number(item?.velocidadeMedia).toFixed(0) || 0
                              } km/h`}
                            </Typography.Text>
                          </Typography.Paragraph>
                          {/* Categoria */}
                          <Typography.Paragraph
                            style={{ marginBottom: "1px", fontSize: "13px" }}
                          >
                            <Typography.Text>-Categoria: </Typography.Text>
                            <Typography.Text strong>
                              {item?.tipo || ""}
                            </Typography.Text>
                          </Typography.Paragraph>
                          <Typography.Paragraph
                            style={{ marginBottom: "1px", fontSize: "13px" }}
                          >
                            <Typography.Text>
                              -Identificação Vagão:{" "}
                            </Typography.Text>
                            <Typography.Text strong>
                              {item?.identificacaoVagao || ""}
                            </Typography.Text>
                          </Typography.Paragraph>

                          <Typography.Paragraph
                            style={{ marginBottom: "1px", fontSize: "13px" }}
                          >
                            <Typography.Text>
                              -Peso Max Permitido:{" "}
                            </Typography.Text>
                            <Typography.Text strong>
                              {`${Intl.NumberFormat("pt-BR").format(
                                item.pesoMaximoPermitido || 0
                              )} TNS`}
                            </Typography.Text>
                          </Typography.Paragraph>
                          {/* PEso Vagao */}
                          <Typography.Paragraph
                            style={{ marginBottom: "1px", fontSize: "13px" }}
                          >
                            <Typography.Text>-Peso Vagão: </Typography.Text>
                            <Typography.Text
                              strong
                              style={{
                                backgroundColor:
                                  item?.alerta && item.status === "max_exceeded"
                                    ? "red"
                                    : "",
                                color:
                                  item?.alerta && item.status === "max_exceeded"
                                    ? "#ffffff"
                                    : "",
                              }}
                            >
                              {`${Intl.NumberFormat("pt-BR").format(
                                item?.pesoFinal || 0
                              )} TNS`}
                            </Typography.Text>
                          </Typography.Paragraph>
                          {/* COnflito vagoes anteriores */}
                          {item.vagoesComOcorrencia.length > 0 && (
                            <Typography.Paragraph
                              style={{
                                marginBottom: "1px",
                                fontSize: "13px",
                              }}
                            >
                              <Typography.Text>-Alarme: </Typography.Text>
                              <Table
                                key={"_id"}
                                size="small"
                                bordered={true}
                                pagination={false}
                                dataSource={item.vagoesComOcorrencia}
                                columns={[
                                  { title: "Vagão", dataIndex: "ordemVagao" },
                                  {
                                    title: "Peso",
                                    dataIndex: "pesoFinal",
                                    render(value, record, index) {
                                      return Intl.NumberFormat("pt-BR").format(
                                        value || 0
                                      );
                                    },
                                  },
                                  {
                                    title: "Dif",
                                    dataIndex: "dif",
                                    render(value, record, index) {
                                      return Intl.NumberFormat("pt-BR").format(
                                        value || 0
                                      );
                                    },
                                  },
                                ]}
                              />
                            </Typography.Paragraph>
                          )}
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              </Badge.Ribbon>
            )}
          />
        </CPanel>
      </Row>
    </>
  );
};
export default BalancaFerroviariaComponent;
