import { Layout } from "antd";

import { Menu, theme } from "antd";
import React, { ReactNode, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useBroker } from "../../context/BrokerContext";
import LogoComponent from "../Logo";
import { isMobile } from "react-device-detect";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Sider, Content } from "./styles";

type IPageWrapperProps = {
  header?: boolean;
  sideBar?: boolean;
  overflow?: boolean;
  page?: string;
  children?: ReactNode;
};

const PageWrapper: React.FC<IPageWrapperProps> = ({ children, sideBar }) => {
  const { currentBroker } = useBroker();
  const { authenticated } = useAuth();

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {sideBar && (
        <Sider
          breakpoint="lg"
          {...(isMobile ? { collapsedWidth: 0 } : { collapsible: true })}
          collapsed={collapsed}
          onCollapse={(value: any) => setCollapsed(value)}
        >
          <LogoComponent />
          {authenticated && (
            <Menu
              theme={"dark"}
              mode={"inline"}
              items={currentBroker.navigationMenu}
            />
          )}
        </Sider>
      )}

      <Layout>
        <Header />
        <Content>{children}</Content>

        <Footer
          versionMajor={currentBroker.versionControl.major}
          versionMinor={currentBroker.versionControl.minor}
          versionPatch={currentBroker.versionControl.patch}
        />
      </Layout>
    </Layout>
  );
};

export default PageWrapper;
