import { useCallback, useEffect, useState } from "react";
import { Card, Col, Form, Row, Statistic, Tabs, message } from "antd";
import { Container, Action, Divider, Button } from "./style";
import { EditBDTForm, NewBDTForm } from "../../constants/BDT";
import { TitlePage } from "../../../../components/TitlePage/TitlePage";
import CModal from "../../../../components/Modal/Modal";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";
import { CiFileOn } from "react-icons/ci";
import { PiPrinterThin } from "react-icons/pi";
import { IUserInfo, useAuth } from "../../../../context/AuthContext";
import BE from "../BE";
import BDT from "../BDT";
import { ICarregamento } from "../../../../interfaces/ICarregamento";
import { useForm } from "antd/es/form/Form";
import CCard from "../../../BalancaFerroviaria/pages/BalancaFerroviaria/components/CCard";
import { EditCarregamentoForm } from "../../constants/Carregamento";
import OperacaoCarregamento from "../OperacaoCarregamento";
import { IOperacaoCarregamento } from "../../../../interfaces/IOperacaoCarregamento";

const CarregamentoComponent = () => {
  const [form] = useForm();
  const [indexTab, setIndexTab] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [carregamento, setCarregamento] = useState<ICarregamento>();
  const [eventoCarregamento, setEventoCarregamento] = useState<any>({});
  const [eventoCarregamentos, setEventoCarregamentos] = useState<any[]>([]);
  const [operacaoCarregamento, setOperacaoCarregamento] =
    useState<IOperacaoCarregamento[]>();
  const [showModalBdt, setShowModalBDT] = useState(false);
  const [showEditModalBDT, setShowEditModalBDT] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUserInfo>();

  let { id } = useParams();
  const { user } = useAuth();

  const handleShowModalBdt = () => setShowModalBDT(true);

  const handleShowEditModalBdt = (params: any) => {
    setEventoCarregamento(params);
    setShowEditModalBDT(true);
  };

  const onHandleDataCarregamento = useCallback(() => {
    setLoading(true);

    api
      .get<ICarregamento>(`carregamento/${id}`)
      .then(({ data }) => {
        setCarregamento(data);
      })
      .catch((e) => message.error("Erro ao buscar os dados. Tenta novamente"))
      .finally(() => setLoading(false));
  }, []);

  const onHandleDataEventoCarregamento = () => {
    setLoading(true);
    api
      .get(`/eventoCarregamento/getEventos?carregamentoId=${id}`)
      .then(({ data }) => {
        setEventoCarregamentos(data);
      })
      .catch((e) => message.error("Erro ao buscar os dados. Tenta novamente"))
      .finally(() => setLoading(false));
  };

  const onHandleDataOperacaoCarregamento = () => {
    setLoading(true);
    api
      .get(`/operacaoCarregamento?carregamentoId=${id}`)
      .then(({ data }) => {
        setOperacaoCarregamento(data);
      })
      .catch((e) => message.error("Erro ao buscar os dados. Tenta novamente"))
      .finally(() => setLoading(false));
  };

  const onChangeTab = (
    params: React.MouseEvent | React.KeyboardEvent | string
  ) => {
    setIndexTab(params);
  };

  const onSave = (params: any) => {
    setLoading(true);
    api
      .post(`/eventoCarregamento`, {
        ...params,
        userId: currentUser?.userId,
        carregamentoId: carregamento?._id,
      })
      .then(({}) => {
        message.success("Evento salvo com sucesso");
        onHandleDataEventoCarregamento();
        setShowModalBDT(false);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => message.error("Erro ao salvar os eventos do BDT"));
  };

  const onDelete = (id: any) => {
    api
      .delete(`/eventoCarregamento/${id}`)
      .then(() => message.success("Evento removido com sucesso"))
      .catch(() => message.error(`Erro ao excluir o evento`))
      .finally(() => {
        onHandleDataEventoCarregamento();
        setLoading(false);
      });
  };

  const onEdit = (params: any) => {
    setLoading(true);
    api
      .put(`/eventoCarregamento/${params?._id}`, {
        ...params,
        userId: currentUser?.userId,
        carregamentoId: carregamento?._id,
      })
      .then((_) => {
        message.success("Evento atualizado com sucesso");
        onHandleDataEventoCarregamento();
      })
      .catch(() => message.error("Erro ao atualizar o evento do BDT"))
      .finally(() => {
        setLoading(false);
        setShowEditModalBDT(false);
      });
  };

  useEffect(() => {
    onHandleDataCarregamento();
  }, []);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    onHandleDataEventoCarregamento();
  }, []);

  useEffect(() => {
    onHandleDataEventoCarregamento();
  }, [indexTab]);

  useEffect(() => {
    onHandleDataOperacaoCarregamento();
  }, [indexTab]);

  return (
    <Container>
      <Row gutter={[8, 32]}>
        <Col span={24}>
          <TitlePage title="Carregamento Ferroviário" />
        </Col>

        <Col span={24}>
          <Action>
            <Button
              loading={loading}
              // type="primary"
              icon={<CiFileOn size={16} />}
              onClick={handleShowModalBdt}
            >
              Gerar BDT
            </Button>
            {/* <Button
              loading={loading}
              //   type="primary"
              icon={<PiPrinterThin size={16} />}
            >
              Imprimir BDT
            </Button> */}
            {/* <Button
              loading={loading}
              //   type="primary"
              icon={<PiPrinterThin size={16} />}
            >
              Imprimir BE
            </Button> */}
          </Action>
        </Col>

        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Prefixo"
              value={carregamento?.prefixoEntrada || ""}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Produto"
              value={carregamento?.produtoId?.descricao || ""}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Proprietário"
              value={carregamento?.proprietarioId?.descricao || ""}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Máquinas em Operação"
              value={String((carregamento?.maquinas || []).length || "")}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24}>
          <div style={{ backgroundColor: "#ffff", padding: 10 }}>
            <Tabs
              type="card"
              defaultActiveKey="3"
              onChange={onChangeTab}
              items={[
                {
                  key: "1",
                  label: "Boletim Diário de Trens",
                  children: (
                    <BDT
                      loading={loading}
                      eventoCarregamento={eventoCarregamentos}
                      onDelete={onDelete}
                      onEdit={handleShowEditModalBdt}
                      showBtnDelete={true}
                      showBtnEdit={true}
                    />
                  ),
                },
                {
                  key: "2",
                  label: "Boletim Embarque",
                  children: (
                    <BE
                      setLoading={() => setLoading}
                      loading={loading}
                      eventoCarregamento={eventoCarregamentos}
                      carregamento={carregamento}
                    />
                  ),
                },
                {
                  key: "3",
                  label: "Operação de Carregamento",
                  children: (
                    <OperacaoCarregamento
                      data={operacaoCarregamento as any}
                      loading={loading}
                    />
                  ),
                },
              ]}
            />
          </div>
        </Col>
      </Row>

      <CModal
        open={showModalBdt}
        title="Adicionar Evento"
        width={1000}
        onCancel={() => setShowModalBDT(false)}
        children={NewBDTForm({ onFinish: onSave })}
        hiddenFooterButton={true}
        destroyOnClose={true}
        forceRender={true}
      />

      <CModal
        open={showEditModalBDT}
        title="Editar Evento"
        width={1000}
        onCancel={() => setShowEditModalBDT(false)}
        children={EditBDTForm({
          onFinish: onEdit,
          form,
          data: eventoCarregamento,
        })}
        hiddenFooterButton={true}
        destroyOnClose={true}
        forceRender={true}
      />
    </Container>
  );
};

export default CarregamentoComponent;
