import { Col, Typography, Collapse, theme } from "antd";
import { ReactNode } from "react";
import { AiFillDownCircle, AiFillRightCircle } from "react-icons/ai";
interface ICPanel {
  colSpan?: number;
  colXs?: number;
  colSm?: number;
  colMd?: number;
  colLg?: number;
  title?: string;
  open?: boolean;
  useBackGroundColorHeader?: boolean;
  children?: ReactNode;
  idKey?: any;
  onChange?: (e: any) => void;
}
const CPanel: React.FC<ICPanel> = ({
  children,
  colLg,
  colMd,
  colSm,
  colSpan,
  colXs,
  idKey,
  open,
  title,
  useBackGroundColorHeader,
  onChange,
}) => {
  const { Panel } = Collapse;
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Col span={colSpan} xs={colXs} sm={colSm} md={colMd} lg={colLg}>
      <Collapse
        defaultActiveKey={[1, 3, 4]}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <AiFillDownCircle color={token?.Layout?.colorBgHeader} size={20} />
          ) : (
            <AiFillRightCircle color={token?.Layout?.colorBgHeader} size={20} />
          )
        }
        {...(onChange ? { onChange: onChange } : {})}
      >
        <Panel
          key={idKey}
          header={
            <Typography.Text
              strong
              style={{
                textAlign: "center",
              }}
            >
              {title}
            </Typography.Text>
          }
        >
          {children}
        </Panel>
      </Collapse>
    </Col>
  );
};

export default CPanel;
