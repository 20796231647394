import { ColumnsTableResumoEquipamento } from "../../constants/ResumoEquipamento";
import { Table } from "./style";

interface IResumoEquipamento {
  loading?: boolean;
  data?: any[];
}
const ResumoEquipamento: React.FC<IResumoEquipamento> = ({ loading, data }) => (
  <Table
    size="small"
    loading={loading}
    columns={ColumnsTableResumoEquipamento()}
    dataSource={data}
    expandable={{
      expandedRowRender: (record) => (
        <Table
          size="small"
          bordered
          dataSource={record?.vagoes || []}
          columns={[
            { title: "Vagão", dataIndex: "vagao" },
            { title: "Peso", dataIndex: "peso" },
          ]}
        />
      ),
      rowExpandable: (record) => record.maquina !== "Not Expandable",
    }}
  />
);

export default ResumoEquipamento;
